<template>
  <v-container fluid ma-0 pa-0>

      <!-- Section 1 - Donna Centrelli... -->
      <v-card 
        id="Home"
        color="secondary"
        dark
        flat
        rounded=0
        width="100%"
        class="pa-4"
      >
        <v-card-title class="headline primary--text">
          Donna Centrelli <br> Certified Spiritual Director
        </v-card-title>

        <v-card-subtitle class="white--text">Providing Guidance for your Spiritual Growth</v-card-subtitle>

        <v-card-text class="white--text">
          It is my desire to accompany people on their personal, unique spiritual journey by creating a sacred and safe space where each person can feel connected to their authentic self and experience a loving and purpose filled life.
          As Teihard de Chardin stated "You are not a human being in search of a spiritual experience.  You are a spiritual being immersed in a human experience."  This means that our spirituality is related to every aspect of our life and every aspect of our life can benefit from Spiritual Direction.
        </v-card-text>
      </v-card>

      <!-- Section 2 - Flower image -->
      <v-card
        flat
        rounded=0
        width="100%"
      >
        <v-img
          src="../assets/flower.jpg"
          height="300px"
        >
        </v-img>
      </v-card>

      <!-- Section 3 - You may seek... -->
      <v-card
        color="secondary"
        dark
        flat
        rounded=0
        width="100%"
        class="pa-4"
      >
        <v-card-title class="headline primary--text">
          You may seek spiritual direction for many reasons:
        </v-card-title>

        <v-list disabled dense color="transparent">
          <v-list-item-group>
            <v-list-item 
              v-for="(item, i) in reasons"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon>mdi-circle-medium</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>

      <!-- Section 4 - What is Spiritual... -->
      <v-card
        id="About"
        color="white"
        flat
        rounded=0
        width="100%"
        class="pa-4"
      >
        <v-card-title class="headline primary--text">
          What is Spiritual Direction?
        </v-card-title>

        <v-card-text class="accent--text">
          There is a divine light, an inner wisdom, that lies within each of us. This inner wisdom is available to us in every moment, always loving and supporting us towards our highest good. Some people call it God, others call it divine consciousness. There are many names for this creative force. Spiritual Direction helps us open to this wisdom in a way that brings healing to the parts of us in need of attention and love. A Spiritual Director will ask questions and listen with great faith allowing this awareness to expand into every area of our lives, health, personal development, relationships and finances. Spiritual Direction is a transformation process that can help us create a more meaningful life.
        </v-card-text>

        <v-card-actions>
          <v-btn 
          text
          color="secondary"
          elevation="2"
          @click="$vuetify.goTo('#Contact')"
          >
            Contact
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- Section 5 - About the session... -->
      <v-card
        color="primary"
        dark
        flat
        rounded=0
        width="100%"
        class="pa-4 py-8"
      >
        <v-card-title class="headline accent--text">
          About the Session
        </v-card-title>

        <v-card-text class="accent--text">
          Spiritual Direction is offered to people of all faiths and to people who are spiritual but have no religion.  Sessions are one on one and occur once a month or more frequently as requested.  They are confidential, last an hour or more and are affordable. Phone, video and in-person sessions are available.
        </v-card-text>
      </v-card>

      <!-- Section 6 - My Background... -->
      <v-row 
      id="Background" 
      class="secondary pa-8 pb-12"
      no-gutters
      >
        <!-- Column 1 - Profile image -->
        <v-col justify="center" align="center">
          <v-img
            src="../assets/backgroundprofile.jpg"
            width="300px"
          >
          </v-img>
        </v-col>
        <!-- Column 2 - My background description -->
        <v-col class="d-flex">
          <v-card
            color="transparent"
            flat
            width="100%"
            min-width="280px"
            class="pa-4 align-self-center"
          >
            <v-card-title class="headline primary--text">
              My Background
            </v-card-title>

            <v-card-text class="white--text">
              Spiritual direction is a life calling for me. I'm a person of deep faith and my life's mission has been to serve. My certification in the applied practice of spiritual listening and sacred response is from the Guild for Spiritual Guidance, a non-profit contemplative community. I am also a member of Spiritual Directors International. I have been giving and receiving Spiritual Direction for many years.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Section 7 - Testimonials... -->
      <v-row 
      id="Testimonials" 
      class="primary pa-8"
      >
        <v-card
          color="transparent"
          flat
          width="100%"
          align="center"
        >
          <v-card-title class="headline accent--text justify-center">
            Testimonials
          </v-card-title>
          <v-card-subtitle class="accent--text">
            What They’re Saying
          </v-card-subtitle>
        </v-card>
        <!-- Column 1 - Testimonials 1 -->
        <v-col>
          <v-card
            color="white"
            flat
            min-width="200px"
            class="pa-4"
          >
            <v-card-text class="accent--text">
              Thank you for accompanying me in this wonderful process of healing and self discovery.  Your energy is giving me the strength and vision to continue towards the light and peace my soul deserves.
            </v-card-text>

            <v-card-subtitle class="accent--text">
              Gianna Tavarez-Nicasio
            </v-card-subtitle>
          </v-card>
        </v-col>
        <!-- Column 2 - Testimonials 2 -->
        <v-col>
          <v-card
            color="white"
            flat
            min-width="200px"
            class="pa-4"
          >
            <v-card-text class="accent--text">
              Working with Donna as a spiritual adviser and hypnotherapist has been an incredible experience. Although growth is never easy, Donna has a special way of guiding her clients to discover their own answers. In addition, she has a kind way of supporting her clients through their growth and holding them accountable so they can grow and flourish.  I have made more personal growth working with Donna in a short period of time, than I have made with all of the therapists I have ever seen over my lifetime combined.  She is simply amazing!!!
            </v-card-text>

            <v-card-subtitle class="accent--text">
              Wendy Zanella
            </v-card-subtitle>
          </v-card>
        </v-col>
        <!-- Column 3 - Testimonials 3 -->
        <v-col>
          <v-card
            color="white"
            flat
            min-width="200px"
            class="pa-4"
          >
            <v-card-text class="accent--text">
              I have been a seeker of higher truth, wisdom, and personal growth my whole life. Never have I worked with someone who has opened doors with me the way Donna has.  She truly is a gift to anyone wishing to move past limiting beliefs and open up to their magnificence and power. I include her in my daily gratitude.
            </v-card-text>

            <v-card-subtitle class="accent--text">
              Peggy Daly
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>

      <!-- Section 8 - Contact form... -->
      <v-card
        id="Contact"
        color="white"
        flat
        rounded=0
        width="100%"
        class="pa-4"
        align=center
      >
        <v-card-title class="headline primary--text justify-center">
          Contact Me
        </v-card-title>

        <v-card-subtitle class="accent--text">
          donnacentrelli@outlook.com <span class="primary--text">|</span> 973.670.1801
        </v-card-subtitle>

        <ContactForm/>
        <br>

        <v-img
          src="../assets/flowerbottom.jpg"
          class="ma-2"
        >
        </v-img>
      </v-card>
      
  </v-container>
</template>

<script>
import ContactForm from './ContactForm'

export default {
  name: 'AppBody',

  components: {
    ContactForm,
  },

  data: () => ({
    reasons: ["Connection to a higher power", "Support, Encouragement and Insight", "Improved relationship with self and others", "Better health and well being", "Personal sense of meaning and purpose"],
  }),
}
</script>
