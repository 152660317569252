<template>
<v-form
    id='contactForm'
    ref="contactForm"
    v-model="isValid"
    name="contactForm"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
>
    <!-- Netlify form elements -->
    <input type="hidden" name="form-name" value="contactForm" />
    <input type="hidden" name="bot-field" />
    
    <v-text-field
      v-model="name"
      :counter="30"
      :rules="nameRules"
      label="Name"
      required
      name='name'
    ></v-text-field>

    <v-text-field
      v-model="email"
      :rules="emailRules"
      label="E-mail"
      required
      name='email'
    ></v-text-field>

    <v-text-field
      v-model="phone"
      :rules="phoneRules"
      label="Phone"
      hint="(Optional)"
      name='phone'
    ></v-text-field>

    <v-textarea
      v-model="message"
      :counter="200"
      label="Message"
      required
      hint="Type your message here..."
      name='message'
    ></v-textarea>

    <v-btn
      :disabled="!validCheck"
      color="secondary"
      class="mr-4"
      @click="submitForm"
    >
      Submit
    </v-btn>

    <!-- Snackbar for contact success/failure -->
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
    >
      {{ snackbarMsg }}

      <template>
        <v-btn
          color="secondary"
          text
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
export default {
  name: 'ContactForm',

  data: () => ({
    //Form fields
    isValid: false,
    name: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 30) || 'Name must be less than 30 characters',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    phone: '',
    phoneRules: [
      v => (v && v.length > 0 && !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(v)) ? 'Phone must be valid' : true
    ],
    message: '',
    snackbar: false,
    snackbarMsg: '',
    snackbarMsgSucess: 'Thanks for reaching out! Your form was successfully submitted.',
    snackbarMsgFailure: 'There was an issue submitting the form, please try again',
  }),

  computed: {
    validCheck: function() {
      //ValidCheck
      return this.isValid && this.message !== ''
    },
  },

  methods: {
    submitForm: function() {
      //Submit form
      //Prepare form data for netlify form post
      let myForm = document.getElementById('contactForm');
      let formData = new FormData(myForm);
      //Send post to root endpoint to trigger netlify form submission
      fetch('/', {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString()
      }).then((response) => {
        //Check request response
        if (response.ok) {
          //Successful submit
          console.log('Form successfully submitted');
          this.snackbarMsg = this.snackbarMsgSucess;
          this.snackbar = true;
          this.$refs.contactForm.reset();
        } else {
          //Error with fetch result (404)
          console.log('Error submitting form. Response: ' + response.status);
          this.snackbarMsg = this.snackbarMsgFailure;
          this.snackbar = true;
        }
      }).catch((error) => {
        //Network error
        console.log('Error submitting form. Error: ' + error);
      })
    }
  }
}
</script>
