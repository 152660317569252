<template>
  <v-app class="appOverflow">

    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-toolbar-title class="accent--text">Donna Centrelli - Spiritual Direction</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon class="hidden-md-and-up" color="accent" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-row
        align="center"
        justify="end"
        class="hidden-sm-and-down"
      >

        <v-btn 
          text 
          color="accent" 
          v-for="(item, i) in navItems"
          :key="i"
          @click="$vuetify.goTo('#' + item)"
        >
        {{ item }}
        </v-btn>

      </v-row>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      bottom
      temporary
      color="primary"
      :class="[drawer ? 'iphoneFlexFix' : 'iphoneFlexFix2']"
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group active-class="secondary--text">
          <v-list-item 
            v-for="(item, i) in navItems"
            :key="i"
            @click="goToNavItem(item)"
          >
            <v-list-item-title v-text="item"></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <AppBody/>
    </v-main>

    <v-footer padless color="accent lighten-1">
      <v-col
        class="text-center white--text"
        cols="12"
      >
        ©{{ new Date().getFullYear() }} Donna Centrelli Spiritual Direction
      </v-col>
    </v-footer>

  </v-app>
</template>

<script>
import AppBody from './components/AppBody';

export default {
  name: 'App',

  components: {
    AppBody,
  },

  data: () => ({
    //Modal drawer for mobile
    drawer: false,
    navItems: ["Home", "About", "Background", "Testimonials", "Contact"]
  }),

  methods: {
    goToNavItem: function(navItem) {
      //Only needed for mobile where the drawer needs to be closed
      this.drawer = !this.drawer;
      this.$vuetify.goTo('#' + navItem);
    }
  }
};
</script>

<style scoped>
  .appOverflow {
    overflow-x: hidden;
  }
  .iphoneFlexFix {
    /* This is required to fix bug with ios14 where nav drawer list items are covered when diaplay flex */
    display: list-item !important;
  }
    .iphoneFlexFix2 {
    /* This is required to fix bug with ios14 where nav drawer list items are covered when diaplay flex */
    display: flex !important;
  }
</style>